import React, { useState, useEffect } from "react";

export default function TestNewDashboards() {
  let [data, setData] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem("token");

    console.log("Got the auth token as", token);

    fetch("https://dashboards.sustainabilitytool.com/project/abcd1234", {
      //headers: {"Authorization": `JWT ${token}`},
      mode: "cors", // no-cors
      referrerPolicy: "origin-when-cross-origin"
    })
      .then(response => {
        console.log("got response", response);
        return response.json();
      })
      .then(data => {
        console.log("got response data", data);
        setData(data);
      });
  }, []);

  return (
    <div className="container">
      <h1>This is a new dashboard test</h1>
      <p>Response data:</p>
      <pre>{JSON.stringify(data, null, 4)}</pre>
    </div>
  );
}
