import React, { useState, useEffect } from "react";
import LoginForm from "storybook-dashboard/auth/login";
import { getAzureConfig } from "storybook-dashboard/auth/azure";
import { BSCard } from "traec-react/utils/bootstrap";

function Section({ children }) {
  return (
    <section className="home-section mt-5">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </section>
  );
}

function RegisterSection({ content, authStatus, isAuthenticated }) {
  console.log("Rendering external register section", content);
  if (!content) {
    return null;
  }
  return (
    <Section>
      <div className="col-sm">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      {isAuthenticated || isAuthenticated == "confirmed" ? (
        <div className="col-md d-flex justify-content-center align-items-center">
          <BSCard
            widthOffset="col-sm-12"
            title="Log in"
            body={
              <div>
                <p>Click below to access your account</p>
                <button className="btn btn-primary btn-sm" onClick={() => (location.href = "/accounts/profile")}>
                  Access
                </button>
              </div>
            }
          />
        </div>
      ) : (
        <div className="col-md d-flex justify-content-center align-items-center">
          <BSCard
            widthOffset="col-sm"
            title="Log in"
            body={(
              <LoginForm 
                createText="" 
                azureConfig={getAzureConfig()} 
              />
            )}
          />
        </div>
      )}
    </Section>
  );
}

const fetchExternalContent = (url, key, updateState) => {
  // Use a standard fetch as a starting point
  console.log("Fetching from external url", url);
  fetch(url)
    .then(response => response.text())
    .then(text => {
      console.log("Got content from external url", url, key);
      updateState(key, text);
    });
};

function ExternalPartContent({ content, children }) {
  if (!content) {
    return null;
  }

  console.log("Rendering external content part here", content, children);
  return children;
}

var changeLandingPageTab = (window.changeLandingPageTab = function(evt, companyName) {
  var i, x, tablinks;
  x = document.getElementsByClassName("company");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablink");
  for (i = 0; i < x.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" activeTab", "");
  }
  document.getElementById(companyName).style.display = "flex";
  evt.currentTarget.className += " activeTab";
});

function ExternalContentHomePage(props) {
  let { externalContent, authStatus, isAuthenticated } = props;

  let [hasFetched, setHasFetched] = useState(false);
  let [state, setState] = useState({});

  const updateState = (key, value) => {
    setState(state => ({ ...state, [key]: value }));
  };

  useEffect(() => {
    if (!hasFetched) {
      externalContent.map((url, key) => fetchExternalContent(url, key, updateState));
      setHasFetched(true);
    }
  });

  console.log("Rendering external home page content here", state);

  return (
    <React.Fragment>
      {/* Content before the login section */}
      <ExternalPartContent content={state.preLogin}>
        <div dangerouslySetInnerHTML={{ __html: state.preLogin }} />
      </ExternalPartContent>

      {/* Content to the left of the login section */}
      <RegisterSection authStatus={authStatus} isAuthenticated={isAuthenticated} content={state.loginLeft} />

      {/* Content after the login section */}
      <ExternalPartContent content={state.postLogin}>
        <div
          dangerouslySetInnerHTML={{
            __html: `${state.postLogin} 
            <script>${changeLandingPageTab}</script>`
          }}
        />
      </ExternalPartContent>
    </React.Fragment>
  );
}

export default ExternalContentHomePage;
