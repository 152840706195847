import React from "react";
import { unitChoices } from "AppSrc/project/metrics/forms";

export const baseMetricFields = {
  name: { value: "", class: "col-sm-12", endRow: true },
  unit: {
    value: unitChoices[0],
    class: "col-sm-6",
    inputType: "select",
    options: unitChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    ))
  },
  conversion_base: {
    value: "",
    label: "Link Conversions for Metric",
    class: "col-sm-6",
    endRow: true
  },
  //category: {value: '', inputType: "hidden", class: 'col-sm-8', endRow: true},
  description: {
    value: "",
    inputType: "tinymce",
    rows: "3",
    class: "col mb-1 mt-1",
    label: "",
    placeholder: "Body",
    config: {
      menubar: false,
      statusbar: false,
      content_css: "/static/bootstrap/css/bootstrap.css",
      plugins: "autolink link image lists print preview",
      block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
      toolbar:
        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat"
    },
    endRow: true
  }
};

export const baseMetricFieldsWithCategory = (categories) => {
  return {
    ...baseMetricFields,
    category: {
      inputType: "select", options: categories.map((category, i) => (
        <option key={i} value={category.get("name")}>
          {category.get("name")}
        </option>
      )).toJS(),
      label: "Issue", class: "col-sm-12", endRow: true
    }
  };
};

export const conversionFactorGroupFields = {
  name: { value: "", class: "col-sm-4" },
  start_date: { label: "Start date", inputType: "date", value: "", class: "col-sm-4" },
  from_group_id: {
    label: "Clone from group",
    value: null,
    class: "col-sm-4",
    inputType: "select",
    options: [],
    endRow: true
  }
};

export const conversionFactorGroupEditFields = {
  name: { value: "", class: "col", disabled: true },
  start_date: { label: "Start date", inputType: "date", value: "", class: "col" }
};

export const conversionFactorFields = {
  metric: { value: "", class: "col-sm-4", label: "From BaseMetric ID" },
  factor: { value: "", inputType: "number", class: "col-sm-4" },
  toUnit: {
    label: "To Unit",
    value: unitChoices[0],
    class: "col-sm-4",
    inputType: "select",
    options: unitChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    )),
    endRow: true
  }
};

export const subTenantFields = {
  domain_url: { value: "", class: "col-sm-4", placeholder: "<auto generate>" },
  admin_company: { value: "", class: "col-sm-4", placeholder: "<default: this.admin_company>" },
  from_company: { value: "", class: "col-sm-4" }
};
