import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { ErrorBoundary } from "traec-react/errors";
import { authFailed } from "traec-react/auth/utils";
import { setNavBarItems } from "traec-react/navBar";

import CompanyInvites from "./companyInvite";
import ProjectInvites from "./projectInvite";
import { ASOUserProjects } from "./project";
import UserCompany from "./company";
import UserNotificationTree from "./tree";

let navbarLinks = [{ label: " ", octicon: "home", to: "/" }];
let userFirstName = "";

function UserProfile(props) {
  let { user, authFailed, dispatch } = props;

  useEffect(() => {
    dispatch(setNavBarItems(navbarLinks));
  }, [dispatch]);

  // if (authFailed) {
  //   return <Redirect to="/accounts/login?next=/accounts/profile/" />;
  // }
  
  // console.log("redux user", state.get(user));
  return (
    <div className="container shadow py-3 rounded">
      <div className="row">
        <div className="col-sm-12">
          <div className="jumbotron jumbotron-fluid bg-light rounded">
            <div className="container d-inline-flex align-items-center justify-content-center">
              <h4 className="display-5 font-weight-bold m-2">Welcome to the Sustainability Tool {userFirstName}</h4>
              {/* <img
                style={{ maxHeight: "120px" }}
                src="https://raw.githubusercontent.com/ActionSustainability/static-content/7c6a472ade628c89be97460915c9a4436943e89e/mainTool/undraw_welcoming.svg"
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <ErrorBoundary>
          <CompanyInvites />
        </ErrorBoundary>
      </div>
      <div className="row">
        <ErrorBoundary>
          <ProjectInvites />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <UserNotificationTree user={user} />
      </ErrorBoundary>
    </div>
  );
};

UserProfile.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  //authFailed: authFailed(state),
  user: state.getInPath(`auth.user`),
  userFirstName: state.getIn(["auth", "user", "first_name"])
});

export default connect(mapStateToProps)(UserProfile);
