import Octicon from "react-octicon";
import { Link } from "react-router-dom";
import React from "react";

export const ReportingPackageStatusItem = ({
  projectId,
  index,
  color,
  refId,
  refName,
  commitId,
  status,
  dates,
  depth
}) => {
  if (!projectId) {
    return null;
  }
  return (
    <React.Fragment>
      <div key={index} className="row" style={{ backgroundColor: color, paddingLeft: `${depth}em` }}>
        <RefName projectId={projectId} refId={refId} refName={refName} />
        <Status status={status} />
        <Dates dates={dates} />
        <GoToReport projectId={projectId} refId={refId} commitId={commitId} />
      </div>
    </React.Fragment>
  );
};

const RefName = ({ projectId, refId, refName }) => {
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  return (
    <div className="col-sm-5 pl-1">
      <Octicon name="file" />
      <Link to={`/project/${projectId}/wpack/${refId}/`}>{refName}</Link>
    </div>
  );
};

const Status = ({ status }) => {
  return (
    <div className="col-sm-2">
      <i>{status}</i>
    </div>
  );
};

const Dates = ({ dates }) => {
  return <div className="col-sm-3">{dates}</div>;
};

const GoToReport = ({ projectId, refId, commitId }) => {
  projectId = projectId ? projectId.substring(0, 8) : projectId;
  refId = refId ? refId.substring(0, 8) : refId;
  commitId = commitId ? commitId.substring(0, 8) : commitId;
  return (
    <div className="col-sm-2">
      <Link className="float-right" to={`/project/${projectId}/wpack/${refId}/report/${commitId ? commitId : ""}`}>
        Go to Report
      </Link>
    </div>
  );
};
